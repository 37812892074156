.user-info {
  display: flex;
  flex-flow: column;
  flex: 1;

  row-gap: 20px;
  width: 100%;
  min-height: calc(100vh - 210px);
}
.info-item {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  width: 100%;

  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;

  h3 {
    font-weight: 600;
    font-size: 18px;
  }
}
