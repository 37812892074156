.header {
  display: flex;
  align-items: center;
  column-gap: 30px;
  height: 90px;
  //border-bottom: 1px solid #eaeaea;


  &__title {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 20px;
    font-weight: 600;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: #eaeaea;
      left: -15px;
    }
  }
}
