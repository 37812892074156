@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #1c1c1c;
  font-family: Ubuntu, sans-serif;
}

body {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.app {
  display: flex;
  flex-flow: column;
  max-width: 800px;
  min-width: 300px;
  width: 100%;
  margin: auto;
}

@import "./styles/Header";
@import "./styles/UserInfo";
@import "./styles/Footer";
