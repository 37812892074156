$mz-logo-animation-speed: 400ms;
$mz-logo-size: 20px;
$mz-logo-color: #1c1c1c;
.mz-logo {
  display: flex;
  column-gap: 2px;
  height: min-content;
  width: max-content;
  //transform: scale(0.3);

  path {
    fill: $mz-logo-color;
  }

  overflow: visible;

  &__m {
    width: $mz-logo-size;
    height: $mz-logo-size;
    overflow: visible;

    path {
      transition: transform $mz-logo-animation-speed ease-in-out;

      &:nth-child(2) {
      }
    }
  }

  &:hover &__m {
    path {
      &:nth-child(2) {
        transform: translateY(20%);
      }
    }
  }

  &__o {
    width: $mz-logo-size;
    height: $mz-logo-size;
    transform-origin: center;
    transition: transform 1600ms ease-in-out;
  }

  &:hover &__o {
    transform: rotate(1080deg);
  }

  &__r {
    width: $mz-logo-size;
    height: $mz-logo-size;

    path {
      transition: transform $mz-logo-animation-speed ease-in-out;
    }

    overflow: visible;

  }

  &:hover &__r {
    path {
      &:nth-child(1) {
        transition-delay: 100ms;

        transform: translateY(20%);
      }

      &:nth-child(2) {
        transition-delay: 10ms;
        transform: translateY(40%);
      }

      &:nth-child(3) {
        transform: translateY(30%);
      }
    }
  }

  &__z {
    width: $mz-logo-size;
    height: $mz-logo-size;

    path {
      transition: transform $mz-logo-animation-speed ease-in-out;
    }

    overflow: visible;

  }

  &:hover &__z {
    path {
      &:nth-child(1) {
        transform: translateX(10%);
      }

      &:nth-child(2) {
        transition-delay: 50ms;
        transform: translateX(10%);
      }
    }
  }

  &__e {
    width: $mz-logo-size;
    height: $mz-logo-size;

    path {
      transition: transform $mz-logo-animation-speed ease-in-out;
    }

    overflow: visible;
  }

  &:hover &__e {
    path {
      &:nth-child(1) {
        transition-delay: 200ms;
        transform: translateX(20%);
      }

      &:nth-child(2) {
        transition-delay: 100ms;
        transform: translateX(40%);
      }
    }
  }
}
